import { DialogContent, DialogOverlay } from '@reach/dialog';
import clsx from 'clsx';
import styles from './Dialog.module.scss';
import { useSelector } from 'react-redux';
import { hasSmartBannerSelector } from '@/store';

const Dialog = ({
	showDialog,
	header = null,
	main = null,
	footer = null,
	dialogClassName = null,
	rootClassName = null,
	headerClassName = null,
	mainClassName = null,
	footerClassName = null,
	onDismiss = () => {},
}) => {
	const hasSmartBanner = useSelector(hasSmartBannerSelector);

	return (
		<DialogOverlay
			isOpen={showDialog}
			aria-label={undefined}
			className={clsx(styles.root, rootClassName)}
			onDismiss={onDismiss}
		>
			<DialogContent className={clsx(styles.dialog, dialogClassName, hasSmartBanner && styles.hasBanner)}>
				{header && <header className={clsx(styles.header, headerClassName)}>{header}</header>}

				{main && <main className={clsx(styles.block, mainClassName)}>{main}</main>}

				{footer && <footer className={clsx(styles.footer, footerClassName)}>{footer}</footer>}
			</DialogContent>
		</DialogOverlay>
	);
};

export default Dialog;
